<template>
  <div class="site-header__item vw-small">
    <a
      href="#site-header-slide-profile-body"
      class=" site-header__button btn btn-slide collapsed"
      id="site-header-slide-profile-open"
      role="button"
      data-parent="#site-header"
      data-toggle="collapse"
      aria-expanded="false"
      aria-haspopup="true"
      :class="{ 'd-none': !isLoggedIn }"
    >
      <i class="icon icon-person_outline"></i>
      <span v-t="'header.menu.my-profile'">My profile</span>
    </a>

    <div
      class="site-header__slide collapse"
      id="site-header-slide-profile-body"
      aria-labelledby="site-header-slide-profile-open"
    >
      <div class="site-header__slide-top-bar">
        <a
          href="#site-header-slide-profile-body"
          class="site-header__button collapsed"
          id="site-header-slide-profile-back"
          role="button"
          data-toggle="collapse"
          aria-expanded="false"
          aria-haspopup="true"
          v-t="'header.menu.my-profile'"
        >
          My profile
        </a>
      </div>

      <div class="collapse__divider" aria-role="presentation"></div>

      <ul class="site-header__items">
        <li class="site-header__item">
          <router-link
            :to="{ name: 'Account Dashboard' }"
            href="#"
            class="site-header__link"
            v-t="'account.sidemenu.account'"
            data-back="site-header-slide-profile-back"
            @click="hideMenu($event)"
          >
            My account
          </router-link>
        </li>

        <li class="site-header__item">
          <router-link
            :to="{ name: 'Orders' }"
            class="site-header__link"
            v-t="'account.sidemenu.orders'"
          >
            Orders
          </router-link>
        </li>

        <li class="site-header__item">
          <a
            href="#"
            class="site-header__link"
            @click.prevent="userLogout($event)"
            v-t="'header.menu.logout'"
          >
            Log out
          </a>
        </li>
      </ul>
    </div>

    <router-link
      :to="{ name: 'Login' }"
      class="site-header__button btn"
      v-if="!isLoggedIn"
    >
      <i class="icon icon-person_outline"></i>
      <span v-t="'header.menu.login'">Login</span>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import headerSlide from "@/mixins/headerSlide";

export default {
  name: "ProfileSmall",
  mixins: [headerSlide],
  methods: {
    ...mapActions(["signOut", "attempt"]),
    userLogout(e) {
      e.preventDefault();
      this.signOut()
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {
          throw new Error("Logout failed");
        });
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "authUser"])
  }
};
</script>
