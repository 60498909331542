<template>
  <!-- @TODO: Add language label: make header.menu.language -->
  <div class="site-header__item vw-small">
    <a
      href="#site-header-slide-language-body"
      class=" site-header__button btn btn-slide collapsed"
      id="site-header-slide-language-open"
      role="button"
      data-parent="#site-header"
      data-toggle="collapse"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <i class="icon icon-language"></i>
      <span v-t="'languages.' + $i18n.locale"></span>
    </a>

    <div
      class="site-header__slide collapse"
      id="site-header-slide-language-body"
      aria-labelledby="site-header-slide-language-open"
    >
      <div class="site-header__slide-top-bar">
        <a
          href="#site-header-slide-language-body"
          class="site-header__button collapsed"
          id="site-header-slide-language-back"
          role="button"
          data-toggle="collapse"
          aria-expanded="false"
          aria-haspopup="true"
          v-t="'languages.language'"
        >
          Language
        </a>
      </div>

      <div class="collapse__divider" aria-role="presentation"></div>

      <ul class="site-header__items">
        <template v-for="locale in locales" :key="locale">
          <li
            v-if="$i18n.locale != locale"
            class="site-header__item"
            data-back="site-header-slide-language-back"
            @click="switchLocale(locale), hideMenu($event)"
          >
            <a
              href="#"
              class="site-header__link"
              target="_self"
              v-t="'languages.' + locale"
            ></a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import headerSlide from "@/mixins/headerSlide";
import i18n from "../../../../i18n";

export default {
  name: "LangSelectSmall",
  mixins: [headerSlide],
  methods: {
    switchLocale(locale) {
      if (i18n.locale !== locale) {
        i18n.locale = locale;
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale } });
        this.$router.push(to);
      }
    }
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",")
    };
  }
};
</script>
