import throttle from "lodash/throttle";
import Collapse from "bootstrap.native/src/components/collapse-native";

import helpers from "@/mixins/helpers";
import header from "@/mixins/header";

export default {
    mixins: [helpers, header],

    data() {
        return {
            headerSlide: {
                slides: {
                    el: null
                }
            }
        }
    },

    mounted() {
        this.elementIsRendered("#site-header-slide-products-body") // this is the "deepest, latest" slide
            .then(() => {
                this.headerSlideInit();
            });
    },
    watch: {
        // Close all collapses on route change, but this isnt working..

        $route: function () {

        }
    },
    created() {

    },
    methods: {
        hideMenu(e) {
            let simulateClick = function (elem) {
                // Create our event (with options)
                let evt = new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window
                });
                // If cancelled, don't dispatch our event
                // let canceled = !elem.dispatchEvent(evt);
            };

            let currentDataBack = e.target.getAttribute("data-back");
            simulateClick(document.getElementById(currentDataBack));

            let header = this.header;
            let that = this;
            header.partWrap.toggle.el.checked = false;
            that.triggerEvent(header.partWrap.toggle.el, "input");
        },
        headerSlideInit() {
            this.headerSlide.slides.el = document.querySelectorAll(".site-header__slide");
            this.headerSlide.slides.el.forEach((slide) => {
                const slideOpener = slide.previousElementSibling;
                const parent = slide.querySelector(".site-header__button");
                const slideOpenerInit = new Collapse(slideOpener);

                slideOpener.addEventListener("click", function (e) {
                    e.preventDefault();
                    slideOpenerInit.show();
                });

                parent.addEventListener("click", function (e) {
                    e.preventDefault();
                    slideOpenerInit.hide();
                });
            });

            if (!this.aboveBreakpoint("md")) {
                this.headerSlideSetSize();
            }

            window.addEventListener("resize", throttle(() => {
                if (!this.aboveBreakpoint("md")) {
                    this.headerSlideSetSize();
                }
            }, 25));

            this.headerSlide.slides.el.forEach((slide) => {
                slide.addEventListener("show.bs.collapse", () => {
                    this.header.wrap.el.classList.add("open-slide");

                    document.body.classList.add("open-slide");
                });
            });

            this.headerSlide.slides.el.forEach((slide) => {
                slide.addEventListener("hide.bs.collapse", () => {
                    this.header.wrap.el.classList.remove("open-slide");

                    document.body.classList.remove("open-slide");
                });
            });
        },

        headerSlideSetSize() {
            const mainHeight = this.headerMainHeight();

            const targetMinHeight = this.noToRemStr(window.innerHeight - this.headerPartTop());

            const itemsTargetMinHeight = this.headerPartHeight() - 1 - (2 * 8), // 1px = .collapse__divider height; 2 * 8px = .site-header__slide .site-header__items margin-y
                itemsTargetHeight = window.innerHeight - this.headerPartTop() - 1 - (2 * 8);

            this.headerSlide.slides.el.forEach((slide) => {
                // The slide
                let topOffset = mainHeight + 1; // 1px = .collapse__divider height

                if (this.isDescendant(this.header.navWrap.el, slide)) {
                    topOffset = topOffset + 8; // 8px = .site-header__navigation's padding-top
                }

                const targetTop = this.noToRemStr(-1 * topOffset);

                slide.style.top = targetTop;
                slide.style.minHeight = targetMinHeight;

                // Its top bar
                const topBar = slide.querySelector(".site-header__slide-top-bar");

                topBar.style.height = this.noToRemStr(mainHeight);

                // Its items
                const itemsEls = slide.querySelectorAll(".site-header__items");

                itemsEls.forEach((itemsEl) => {
                    itemsEl.style.minHeight = this.noToRemStr(itemsTargetMinHeight);
                    itemsEl.style.height = this.noToRemStr(itemsTargetHeight);
                });
            });
        }
    }
};
