<template>
  <footer class="site-footer" id="site-footer">
    <div class="container">
      <div class="site-footer__big-buttons">
        <div class="grid grid--has-subgrid">
          <div class="lang-select dropdown">
            <a
              href="#site-footer-lang-select-body"
              class="btn btn-primary btn-dropdown collapsed"
              id="site-footer-lang-select-toggle"
              role="button"
              data-toggle="collapse"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <i class="icon icon-language"></i>
              <span
                v-t="'languages.' + ($i18n.locale ? $i18n.locale : 'en')"
              ></span>
            </a>

            <div
              class="dropdown__body collapse"
              id="site-footer-lang-select-body"
              data-parent="#site-footer"
              aria-labelledby="site-footer-lang-select-toggle"
            >
              <div class="collapse__body">
                <ul class="collapse__menu list-inline">
                  <template v-for="locale in locales" :key="locale">
                    <li
                      v-if="$i18n.locale != locale"
                      class="collapse__item"
                      @click="switchLocale(locale)"
                    >
                      <a
                        href="#"
                        class="collapse__link"
                        target="_self"
                        v-t="'languages.' + locale"
                      ></a>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>

          <div class="site-footer__shop-buttons">
            <div class="grid">
              <div>
                <router-link
                  :to="{ name: 'Account Dashboard' }"
                  class="btn btn-secondary"
                >
                  <span v-t="'footer.my-profile'">My VETUS</span>
                  <i class="icon icon-person_outline"></i>
                </router-link>
              </div>

              <div v-if="isLoggedIn">
                <router-link :to="{ name: 'Cart' }" class="btn btn-secondary">
                  <span v-t="'footer.cart'">Cart</span>
                  <i class="icon icon-shopping_cart"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="site-footer__navigation">
        <nav class="site-navigation" id="site-footer-nav">
          <ul class="site-navigation__groups">
            <li
              class="site-navigation__group site-navigation__group--has-subgroups"
            >
              <span
                class="site-navigation__group-heading site-navigation__group-heading--mobile text-sm d-inline-flex d-md-none"
                id="site-footer-nav-group-0-heading"
                data-toggle="collapse"
                data-target="#site-footer-nav-group-0"
                aria-controls="site-footer-nav-group-0"
                aria-expanded="false"
                aria-role="button"
              >
                <span v-t="'footer.products-title'">Products</span>
                <i class="icon icon-keyboard_arrow_down"></i>
              </span>

              <span
                class="site-navigation__group-heading text-sm d-none d-md-block"
              >
                <span v-t="'footer.products-title'">Products</span>
                <i class="icon icon-keyboard_arrow_down"></i>
              </span>

              <span class="site-navigation__items">
                <ul
                  class="collapse"
                  id="site-footer-nav-group-0"
                  data-parent="#site-footer-nav"
                  aria-labelledby="site-footer-nav-group-0-heading"
                >
                  <li class="site-navigation__item-group">
                    <ul>
                      <template
                        v-for="(category, key, i) in categories"
                        :key="'footer-category-' + i"
                      >
                        <li
                          class="site-navigation__item text-sm"
                          v-if="
                            i < Math.ceil(Object.keys(categories).length / 2)
                          "
                        >
                          <router-link
                            :to="{ name: 'Category', params: { categorySlug : category.url_path} }"
                            class="site-navigation__link"
                            >{{ category.name }}</router-link
                          >
                        </li>
                      </template>
                    </ul>
                  </li>
                  <li class="site-navigation__item-group">
                    <ul>
                      <template
                        v-for="(category, key, i) in categories"
                        :key="'footer-category-' + i"
                      >
                        <li
                          class="site-navigation__item text-sm"
                          v-if="
                            i >= Math.ceil(Object.keys(categories).length / 2)
                          "
                        >
                          <router-link
                          :to="{ name: 'Category', params: { categorySlug : category.url_path} }"

                            class="site-navigation__link"
                            >{{ category.name }}</router-link
                          >
                        </li>
                      </template>
                    </ul>
                  </li>
                </ul>
              </span>
            </li>

            <li
              class="site-navigation__group"
              v-for="(linkgroup, i) in menuFooter.menus"
              :key="i"
            >
              <span
                class="site-navigation__group-heading site-navigation__group-heading--mobile text-sm d-inline-flex d-md-none"
                :id="`site-footer-nav-group-${i}-heading`"
                data-toggle="collapse"
                :data-target="`#site-footer-nav-group-${i}`"
                :aria-controls="`site-footer-nav-group-${i}`"
                aria-expanded="false"
                aria-role="button"
              >
                <span>{{ linkgroup.title }}</span>
                <i class="icon icon-keyboard_arrow_down"></i>
              </span>

              <span
                class="site-navigation__group-heading text-sm d-none d-md-block"
              >
                <span>{{ linkgroup.title }}</span>
                <i class="icon icon-keyboard_arrow_down"></i>
              </span>

              <span class="site-navigation__items">
                <ul
                  class="collapse"
                  :id="`site-footer-nav-group-${i}`"
                  data-parent="#site-footer-nav"
                  :aria-labelledby="`site-footer-nav-group-${i}-heading`"
                >
                  <li
                    class="site-navigation__item text-sm"
                    v-for="(link, i) in linkgroup.links"
                    :key="i"
                  >
                    <a
                      class="site-navigation__link"
                      :href="link.link.url"
                      :target="link.link.target"
                      v-html="link.link.title"
                    >
                    </a>
                  </li>
                </ul>
              </span>
            </li>
          </ul>
        </nav>
      </div>

      <!-- @TODO: Add site footer links -->
      <div class="site-footer__sites">
        <div
          class="grid grid--smaller"
          v-if="
            menuFooter.flat.top.links != false &&
              menuFooter.flat.top.links != ''
          "
        >
          <div v-for="(link, i) in menuFooter.flat.top.links" :key="i">
            <a
              class="btn btn-sm btn-empty btn-empty-primary btn-white"
              rel="noopener"
              :href="link.link.url"
              :target="link.link.target"
              ><span v-html="link.link.title"> </span
              ><i class="icon icon-keyboard_arrow_right"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="site-footer__social">
        <div class="grid">
          <a href="https://www.facebook.com/VETUS.BV" class="btn btn-sm btn-icon-only" target="_blank" title="">
            <i class="icon icon-social_facebook text-white"></i>
          </a>

          <a href="https://www.instagram.com/vetus_global/" class="btn btn-sm btn-icon-only" target="_blank" title="">
            <i class="icon icon-social_instagram text-white"></i>
          </a>

          <!-- <a href="#" class="btn btn-sm btn-icon-only" target="_blank" title="">
            <i class="icon icon-social_twitter text-white"></i>
          </a> -->

          <a href="https://www.youtube.com/VETUSTV" class="btn btn-sm btn-icon-only" target="_blank" title="">
            <i class="icon icon-social_youtube text-white"></i>
          </a>

          <a href="https://www.linkedin.com/company/vetus/" class="btn btn-sm btn-icon-only" target="_blank" title="">
            <i class="icon icon-social_linkedin text-white"></i>
          </a>
        </div>
      </div>

      <hr />

      <div class="site-footer__copyright">
        <div class="grid grid--smaller">
          <span>&copy; VETUS</span>
        </div>
      </div>

      <div
        class="site-footer__boring-links"
        v-if="
          menuFooter.flat.bottom.links != false &&
            menuFooter.flat.bottom.links != ''
        "
      >
        <div class="grid grid--smaller">
          <div
            class="text-sm"
            v-for="(link, i) in menuFooter.flat.bottom.links"
            :key="i"
          >
            <a
              :href="link.link.url"
              :target="link.link.target"
              v-html="link.link.title"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import helpers from "@/mixins/helpers";
import footerDropdown from "@/mixins/footerDropdown";

import Collapse from "bootstrap.native/src/components/collapse-native";
import { mapGetters } from "vuex";
import i18n from "../../../i18n";

export default {
  mixins: [helpers, footerDropdown],
  computed: mapGetters(["categories", "isLoggedIn"]),
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      menuFooter: {}
    };
  },
  created() {
    this.setMenuLinks(this.$i18n.locale);
    //console.log(this.menuFooter);
  },
  watch: {
    "$i18n.locale": function(newVal, oldVal) {
      this.setMenuLinks(newVal);
    }
  },
  methods: {
     switchLocale(locale) {
       if (i18n.locale !== locale) {
         i18n.locale = locale;
         this.$i18n.locale = locale;
         const to = this.$router.resolve({ params: { locale } });
         this.$router.push(to);
       }
    },
    setMenuLinks(locale) {
      this.menuFooter = require("./locales/footer-" + locale + ".json");
    }
  },
  mounted() {
    const footerCollapseBtns = document.querySelectorAll(
      ".site-navigation__group-heading--mobile"
    );
    footerCollapseBtns.forEach(footerCollapseBtn => {
      if (footerCollapseBtn) {
        new Collapse(footerCollapseBtn);
      }
    });
  }
};
</script>

<style lang="css" scoped></style>
