<template>
  <!-- <section class="breadcrumbs pt-3 pb-6">
    &nbsp;
  </section> -->
  <section class="section breadcrumbs my-3">
    <div class="container">
      <nav
        class="breadcrumbs__nav text-sm"
        aria-label="Breadcrumb"
        id="breadcrumbs"
      >
        <div class="breadcrumbs__items d-flex">
          <template
            v-for="(breadcrumb, i) in breadcrumbs"
            :key="'breadcrumb-' + i"
          >
            <div class="breadcrumbs__item" v-if="breadcrumbs.length != i + 1 && i != 1">

              <router-link
                :to="getLink(breadcrumb)"
                title=""
                class="breadcrumbs__link"
              >
                {{ getName(breadcrumb) }}
              </router-link>

              <i class="icon icon-keyboard_arrow_right"></i>
            </div>

             <span
              class="breadcrumbs__item is-active d-inline-flex align-items-center"
              v-else-if="i = 1"
            >
              <span v-if="!isProduct(breadcrumb)">
                {{ getName(breadcrumb) }}
              </span>
              <span v-else id="breadcrumb-current"></span>

              <i class="icon icon-keyboard_arrow_right"></i>
            </span>

            <span
              class="breadcrumbs__item is-active d-inline-flex align-items-center"
              v-else
            >
              <span v-if="!isProduct(breadcrumb)">
                {{ getName(breadcrumb) }}
              </span>
              <span v-else id="breadcrumb-current"></span>

              <i class="icon icon-keyboard_arrow_right" style="opacity: 0;"></i>
            </span>
          </template>
          <!-- <template v-for="(breadcrumb, i) in crumbs" :key="'breadcrumb-' + i">
            <div class="breadcrumbs__item" v-if="crumbs.length != i + 1">
              <a href="" title="" class="breadcrumbs__link">
                {{ getBreadcrumbName(breadcrumb.text) }}
              </a>
              <i class="icon icon-keyboard_arrow_right"></i>
            </div>
            <span
              class="breadcrumbs__item is-active d-inline-flex align-items-center"
              v-else
            >
              {{ getBreadcrumbName(breadcrumb.text) }}

              <i class="icon icon-keyboard_arrow_right invisible"></i>
            </span>
          </template> -->
        </div>
      </nav>
    </div>
  </section>
  <!-- <section class="breadcrumbs pt-3 pb-6">
    <div class="container">
      <nav class="breadcrumbs__nav text-sm" aria-label="Breadcrumb">
        {{ breadcrumbs }}
      </nav>
    </div>
  </section> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  // props: ,
  data() {
    return {
      categorySlug: "",
      subCategorySlug: ""
    };
  },
  created() {
    // console.log(this.breadcrumbs);
  },
  methods: {
    // getBreadcrumbName(name) {
    //   switch (name) {
    //     case ":categorySlug":
    //       this.categorySlug = this.$route.params["categorySlug"];
    //       if (typeof this.categories[this.categorySlug] !== "undefined") {
    //         return this.categories[this.categorySlug].name;
    //       }
    //       return "";
    //     case ":subCategorySlug":
    //       this.subCategorySlug = this.$route.params["subCategorySlug"];
    //       if (typeof this.categories[this.categorySlug] !== "undefined") {
    //         return this.categories[this.categorySlug].children[
    //           this.subCategorySlug
    //         ].name;
    //       }
    //       return "";
    //   }
    //   return name;
    // },
    isProduct(breadcrumb) {
      if (breadcrumb.routeParam == "productSlug") {
        return true;
      }
    },
    getName(breadcrumb) {
      if (
        typeof breadcrumb.routeParam == "string" &&
        breadcrumb.routeParam != "productSlug"
      ) {
        if (!this[breadcrumb.state][this.$route.params[breadcrumb.routeParam]])
          return "";
        return this[breadcrumb.state][this.$route.params[breadcrumb.routeParam]]
          .name;
      } else if (Array.isArray(breadcrumb.routeParam)) {
        let firstParam = breadcrumb.routeParam[0];
        let secondParam = breadcrumb.routeParam[1];
        if (!this[breadcrumb.state][this.$route.params[firstParam]]) return "";
        if (
          !this[breadcrumb.state][this.$route.params[firstParam]].children[
            this.$route.params[secondParam]
          ]
        )
          return "";
        return this[breadcrumb.state][this.$route.params[firstParam]].children[
          this.$route.params[secondParam]
        ].name;
      } else if (breadcrumb.routeParam == "productSlug") {
        // console.log("is product");
        return (
          this.$route.params[breadcrumb.routeParam].charAt(0).toUpperCase() +
          this.$route.params[breadcrumb.routeParam]
            .slice(1)
            .replaceAll("-", " ")
        );
      }
      return breadcrumb.name;
    },
    getLink(breadcrumb) {
      if (typeof breadcrumb.routeParam == "string") {
        return breadcrumb.link.replace(
          breadcrumb.routeParam,
          this.$route.params[breadcrumb.routeParam]
        );
      }
      return { name: breadcrumb.name };
      // return breadcrumb.link;
    }
  },
  computed: {
    ...mapGetters(["categories", "products"]),
    breadcrumbs: function() {
      // if (this.$route.meta.breadcrumb == "crumbs") {
      //   return this.crumbs;
      // }
      return this.$route.meta.breadcrumb;
    }
    // crumbs: function() {
    //   let pathArray = this.$route.path.split("/");
    //   pathArray.shift();
    //   let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
    //     breadcrumbArray.push({
    //       path: path,
    //       to: breadcrumbArray[idx - 1]
    //         ? "/" + breadcrumbArray[idx - 1].path + "/" + path
    //         : "/" + path,
    //       // text: this.$route.matched[idx].meta.breadCrumb || path
    //       name:
    //         path.charAt(0).toUpperCase() + path.slice(1).replaceAll("-", " ")
    //     });
    //     return breadcrumbArray;
    //   }, []);
    //   return breadcrumbs;
    // }
  }
};
</script>

<style lang="css" scoped></style>
