import helpers from "@/mixins/helpers";

export default {
  mixins: [ helpers ],

  data() {
    return {
      header: {
        wrap: {
          el: null
        },

        container: {
          el: null
        },

        main: {
          el: null
        },

        partWrap: {
          el: null,

          toggle: {
            el: null
          },

          close: {
            el: null
          }
        },

        part: {
          el: null
        },

        navWrap: {
          el: null
        },

        nav: {
          el: null
        }
      }
    }
  },

  mounted() {
    // Wait until the last Vue component has rendered before locating elements.
    // https://forum.vuejs.org/t/render-order-priority/69240/2
    this.elementIsRendered(".site-header__navigation .site-navigation")
      .then(() => {
        this.header.wrap.el = document.querySelector(".site-header");
        this.header.container.el = document.querySelector(".site-header__container");
        this.header.main.el = document.querySelector(".site-header__main");
        this.header.partWrap.el = document.querySelector(".site-header__part-wrap");
        this.header.partWrap.toggle.el = document.querySelector("#site-header-bottom-part-wrap-toggle");
        this.header.partWrap.close.el = document.querySelector("#site-header-bottom-part-wrap-close");
        this.header.part.el = document.querySelector(".site-header__part");
        this.header.navWrap.el = document.querySelector(".site-header__navigation");
        this.header.nav.el = document.querySelector(".site-header__navigation .site-navigation");
      })
      .catch((error) => {
        //console.log(error);
      });
  },

  methods: {
    headerMainHeight() {
      return this.header.main.el.getBoundingClientRect().height;
    },

    headerPartTop() {
      return this.header.part.el.getBoundingClientRect().top;
    },

    headerPartHeight() {
      return this.header.part.el.getBoundingClientRect().height;
    },

    headerNavWrapLeft() {
      return this.header.navWrap.el.getBoundingClientRect().left;
    }
  }
};
