<template>
  <!-- @TODO: Add language label: make header.menu.language -->
  <div class="dropdown dropdown--lang-select">
    <a
      href="#site-header-top-lang-select-body"
      class="btn btn-sm btn-dropdown collapsed"
      id="site-header-top-lang-select-toggle"
      role="button"
      data-parent="#site-header"
      data-toggle="collapse"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <i class="icon icon-language"></i>
      <span v-t="'languages.' + ($i18n.locale ? $i18n.locale : 'en')"></span>
    </a>
    <div
      class="dropdown__body collapse"
      id="site-header-top-lang-select-body"
      aria-labelledby="site-header-top-lang-select-toggle"
    >
      <div class="collapse__body">
        <ul class="collapse__list">
          <template v-for="locale in locales" :key="locale">
            <li
              v-if="$i18n.locale != locale"
              class="collapse__item"
              @click="switchLocale(locale)"
            >
              <a
                href="#"
                class="collapse__link"
                target="_self"
                v-t="'languages.' + locale"
              ></a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../../../i18n";
export default {
  name: "LangSelectWide",
  methods: {
    switchLocale(locale) {
       if (i18n.locale !== locale) {
         i18n.locale = locale;
         this.$i18n.locale = locale;
         const to = this.$router.resolve({ params: { locale } });
         this.$router.push(to);
       }
      
      
    }
  },
  created() {
    if (i18n.locale != undefined) {
      this.$i18n.locale = i18n.locale;
    }
  },
  // watch: {
  //   $route(to, from) {
  //     console.log('Router watch fired!');
  //     console.log(to.params.locale + ' - ' + from.params.locale );

  //     if (
  //       to.params.locale !== from.params.locale         
  //     ) {
  //       console.log(' tot hier!');
  //       i18n.locale = to.params.locale;
  //     }

  //   }
  // },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",")
    };
  }
};
</script>
