import Collapse from "bootstrap.native/src/components/collapse-native";

import helpers from "@/mixins/helpers";

export default {
    mixins: [helpers],

    mounted() {
        this.elementIsRendered("#site-header-collapse-products-body") // this is the "deepest, latest" dropdown
            .then(() => {
                this.headerDropdownInit();
            });
    },

    methods: {
        headerDropdownInit() {
            const dropdowns = document.querySelectorAll(".site-header .dropdown__body");

            dropdowns.forEach((dropdown) => {
                const dropdownToggle = dropdown.previousElementSibling;
                if (dropdownToggle) {
                    new Collapse(dropdownToggle);
                }
            });
        }
    }
};
