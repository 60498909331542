<template>
  <!-- @TODO: Add unit types -->
  <div class="dropdown dropdown--unit-select">
    <a
      href="#site-header-top-unit-select-body"
      class="btn btn-sm btn-dropdown collapsed"
      id="site-header-top-unit-select-toggle"
      role="button"
      data-parent="#site-header"
      data-toggle="collapse"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <i class="icon icon-straighten"></i>
      <template v-if="unit_type == 'metric'">
        <span v-t="'header.units.metric-selected'">
          Metric
        </span>
      </template>
      <template v-else>
        <span v-t="'header.units.imperial-selected'">
          Imperial
        </span>
      </template>
    </a>

    <div
      class="dropdown__body collapse"
      id="site-header-top-unit-select-body"
      aria-labelledby="site-header-top-unit-select-toggle"
    >
      <div class="collapse__body">
        <ul class="collapse__list">
          <li class="collapse__item" @click="selectUnit('metric')">
            <a
              href="#"
              class="collapse__link"
              :class="[unit_type == 'metric' ? ' is-active' : '']"
              v-t="'header.units.metric-option'"
              >Metric (cm, liter)</a
            >
          </li>

          <li class="collapse__item" @click="selectUnit('imperial')">
            <a
              href="#"
              class="collapse__link"
              :class="[unit_type == 'imperial' ? ' is-active' : '']"
              target="_self"
              v-t="'header.units.imperial-option'"
              >Imperial (inch, gallon)</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnitSelectWide",
  data() {
    return {
      unit_type: localStorage.getItem("unit_type")
    };
  },
  mounted() {
    if (localStorage.unit_type) {
      this.unit_type = localStorage.unit_type;
    } else {
      localStorage.unit_type = "metric";
    }
  },
  watch: {
    $route(to, from) {
      if (!localStorage.unit_type) {
        localStorage.unit_type = "metric";
      }
    }
  },
  methods: {
    selectUnit(value) {
      localStorage.unit_type = value;
      this.unit_type = value;
      location.reload(); // You cant watch localStorage, else use vuex state.
    }
  }
};
</script>
