<template>
  <!-- @TODO: Add unit types -->
  <div class="site-header__item vw-small">
    <a
      href="#site-header-slide-units-body"
      class=" site-header__button btn btn-slide collapsed"
      id="site-header-slide-units-open"
      role="button"
      data-parent="#site-header"
      data-toggle="collapse"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <i class="icon icon-straighten"></i>
      <template v-if="unit_type == 'metric'">
        <span v-t="'header.units.metric-selected'">
          Metric
        </span>
      </template>
      <template v-else>
        <span v-t="'header.units.imperial-selected'">
          Imperial
        </span>
      </template>
    </a>

    <div
      class="site-header__slide collapse"
      id="site-header-slide-units-body"
      aria-labelledby="site-header-slide-units-open"
    >
      <div class="site-header__slide-top-bar">
        <a
          href="#site-header-slide-units-body"
          class="site-header__button collapsed"
          id="site-header-slide-units-back"
          role="button"
          data-toggle="collapse"
          aria-expanded="false"
          aria-haspopup="true"
          v-t="'header.units.units'"
        >
          Units
        </a>
      </div>

      <div class="collapse__divider" aria-role="presentation"></div>

      <ul class="site-header__items">
        <li class="site-header__item" @click="selectUnit('metric')">
          <a
            href="#"
            class="site-header__link is-active"
            :class="[unit_type == 'metric' ? ' is-active' : '']"
            v-t="'header.units.metric-option'"
            data-back="site-header-slide-unit-back"
            @click="hideMenu($event)"
          >
            Metric (cm, liter)
          </a>
        </li>

        <li class="site-header__item" @click="selectUnit('imperial')">
          <a
            href="#"
            class="site-header__link"
            :class="[unit_type == 'imperial' ? ' is-active' : '']"
            v-t="'header.units.imperial-option'"
            data-back="site-header-slide-unit-back"
            @click="hideMenu($event)"
          >
            Imperial (inch, gallon)
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import headerSlide from "@/mixins/headerSlide";
export default {
  name: "UnitSelectSmall",
  mixins: [headerSlide],
  data() {
    return {
      unit_type: localStorage.getItem("unit_type")
    };
  },
  mounted() {
    if (localStorage.unit_type) {
      this.unit_type = localStorage.unit_type;
    } else {
      localStorage.unit_type = "metric";
    }
  },
  watch: {
    $route(to, from) {
      if (!localStorage.unit_type) {
        localStorage.unit_type = "metric";
      }
    }
  },
  methods: {
    selectUnit(value) {
      localStorage.unit_type = value;
      this.unit_type = value;
      location.reload(); // You cant watch localStorage, else use vuex state.
    }
  }
};
</script>
