<template>
  <header class="site-header" id="site-header">
    <HeaderUsps />

    <HeaderTop />

    <hr class="site-header__separator" />

    <HeaderMain />

    <hr class="site-header__separator" />

    <Breadcrumbs
      v-if="$route && $route.meta.breadcrumb != undefined && categories"
    ></Breadcrumbs>

    <slot></slot>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import throttle from "lodash/throttle";

import helpers from "@/mixins/helpers";
import header from "@/mixins/header";
import headerDropdown from "@/mixins/headerDropdown";
import headerSlide from "@/mixins/headerSlide";

import HeaderUsps from "./HeaderUsps.vue";
import HeaderTop from "./HeaderTop.vue";
import HeaderMain from "./HeaderMain.vue";
import Breadcrumbs from "../../../components/Misc/Breadcrumbs.vue";

export default {
  components: {
    HeaderUsps,
    HeaderTop,
    HeaderMain,
    Breadcrumbs
  },

  mixins: [helpers, header, headerDropdown, headerSlide],

  created() {
    this.retrieveCustomerCartId();
    // this.retrieveGuestCartId();
    this.retrieveCart();
    this.fetchCategories();
  },

  mounted() {
    this.elementIsRendered(".site-header__main").then(() => {
      this.headerInit();
    });
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "authUser",
      "cart",
      "cartCount",
      "categories",
      "products"
    ])
  },

  methods: {
    ...mapActions([
      "signOut",
      "attempt",
      "retrieveCart",
      "retrieveCustomerCartId",
      // "retrieveGuestCartId",
      "fetchCategories"
    ]),

    // Site header
    headerInit() {
      this.headerSetSize();

      window.addEventListener("resize", throttle(this.headerSetSize, 25));
    },

    headerSetSize() {
      if (this.aboveBreakpoint("md")) {
        if (this.header.part.el.hasAttribute("style")) {
          this.header.part.el.removeAttribute("style");
        }

        return;
      }

      const partTargetMinHeight = this.noToRemStr(
        window.innerHeight - this.headerPartTop()
      );

      this.header.part.el.style.minHeight = partTargetMinHeight;
    },

    userLogout(e) {
      e.preventDefault();

      this.signOut()
        .then(() => {
          this.$router.push({ name: 'Login' });
        })
        .catch(() => {
          // console.log("Logout failed");
        });
    }
  }
};
</script>
