import Collapse from "bootstrap.native/src/components/collapse-native";

import helpers from "@/mixins/helpers";

export default {
    mixins: [helpers],
    mounted() {
        this.elementIsRendered(".lang-select .dropdown__body") // this is the "deepest, latest" dropdown
            .then(() => {
                this.footerDropdownInit();
            });
    },
    methods: {
        footerDropdownInit() {
            const dropdowns = document.querySelectorAll(".site-footer .dropdown__body");

            dropdowns.forEach((dropdown) => {
                const dropdownToggle = dropdown.previousElementSibling;
                if (dropdownToggle) {
                    new Collapse(dropdownToggle);
                }
            });
        }
    }
};
