<template>
  <!-- @TODO: Add products menu label -->
  <nav class="site-navigation">
    <ul class="site-header__items">
      <li class="site-header__item vw-small">
        <a
          href="#site-header-slide-products-body"
          class="site-header__button site-header__button--special btn btn-slide collapsed"
          id="site-header-slide-products-open"
          role="button"
          data-parent="#site-header"
          data-toggle="collapse"
          aria-expanded="false"
          aria-haspopup="true"
          v-t="'header.menu.products'"
        >
          Products
        </a>

        <div
          class="site-header__slide collapse"
          id="site-header-slide-products-body"
          aria-labelledby="site-header-slide-products-open"
        >
          <div class="site-header__slide-top-bar">
            <a
              href="#site-header-slide-products-body"
              class="site-header__button collapsed"
              id="site-header-slide-products-back"
              role="button"
              data-toggle="collapse"
              aria-expanded="false"
              aria-haspopup="true"
              v-t="'header.menu.products'"
            >
              Products
            </a>
          </div>

          <div class="collapse__divider" aria-role="presentation"></div>

          <ul class="site-header__items">
            <li
              v-for="(category, i) in categories"
              :key="i"
              class="site-header__item"
            >
              <!-- <li class="site-header__item"> -->

              <router-link
                :to="{ name: 'Category', params: { categorySlug : category.url_path} }"
                class="site-header__link"
                data-back="site-header-slide-products-back"
                @click="hideMenu($event)"
              >
                {{ category.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="site-header__item vw-wide">
        <a
          href="#site-header-collapse-products-body"
          class="site-header__button site-header__button--special btn btn-dropdown collapsed"
          id="site-header-collapse-products-toggle"
          role="button"
          data-parent="#site-header"
          data-toggle="collapse"
          aria-expanded="false"
          aria-haspopup="true"
        >
          <span v-t="'header.menu.products'">Products</span>
        </a>

        <!-- Products overlay -->
        <div
          class="dropdown__body dropdown__body--wide collapse w-100"
          id="site-header-collapse-products-body"
          aria-labelledby="site-header-collapse-products-toggle"
        >
          <div
            class="collapse__body collapse__body--wide container"
            v-if="!isFetchingCategories"
          >
            <ul class="collapse__menu collapse__menu--wide row">
              <li
                v-for="(category, i) in categories"
                :key="i"
                class="collapse__item collapse__item--wide col-6 col-md-4 col-lg-3"
              >

              <router-link
                  :to="{ name: 'Category', params: { categorySlug : category.url_path} }"
                  class="collapse__link collapse__link--wide"
                >
                  <div class="category-media media ratio">
                    <div class="media__wrapper">
                      <img :src="category.image" :alt="category.name" />
                    </div>
                  </div>

                  <span class="category-title">
                    {{ category.name }}
                  </span>
                </router-link>
              </li>
            </ul>

            <div class="text-center">
              <router-link :to="{ name: 'Products' }" class="btn btn-secondary">
                <span v-t="'header.menu.all-products'">
                  View all products
                </span>
                <i class="icon icon-keyboard_arrow_right"></i>
              </router-link>
            </div>
          </div>
          <div v-else-if="isFetchingCategories" class="text-center py-5">
            <!-- @TODO: Loading label -->
            Loading
          </div>
        </div>
      </li>

      <li
        v-for="(link, i) in menuHeader.menus.links"
        :key="i"
        class="site-header__item"
      >
        <a
          :href="link.link.url"
          :target="link.link.target"
          class="site-header__link"
          v-html="link.link.title"
        >
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import throttle from "lodash/throttle";

import helpers from "@/mixins/helpers";
import header from "@/mixins/header";

export default {
  name: "HeaderNav",

  mixins: [helpers, header],

  data() {
    return {
      headerProds: {
        dropdown: {
          el: null
        }
      },
      menuHeader: {}
    };
  },
  watch: {
    "$i18n.locale": function(newVal, oldVal) {
      this.setMenuLinks(newVal);
    }
  },
  mounted() {
    // Site header - products dropdown (wide viewport)
    this.elementIsRendered(".site-header__navigation").then(() => {
      this.headerProdsInit();
    });
  },
  computed: mapGetters(["categories", "isFetchingCategories"]),
  created() {
    this.setMenuLinks(this.$i18n.locale);
    //console.log(this.menuHeader);
  },
  methods: {
    hideMenu(e) {
      let simulateClick = function(elem) {
        // Create our event (with options)
        let evt = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        });
        // If cancelled, don't dispatch our event
        // let canceled = !elem.dispatchEvent(evt);
      };

      let currentDataBack = e.target.getAttribute("data-back");
      simulateClick(document.getElementById(currentDataBack));

      let header = this.header;
      let that = this;
      header.partWrap.toggle.el.checked = false;
      that.triggerEvent(header.partWrap.toggle.el, "input");
    },
    // ...mapActions(["fetchCategories"]),
    // Site header - products dropdown (wide viewport)
    headerProdsInit() {
      this.headerProds.dropdown.el = document.querySelector(
        "#site-header-collapse-products-body"
      );

      if (this.aboveBreakpoint("md")) {
        this.headerProdsSetPosition();
        this.headerProdsSetWidth();
      }

      window.addEventListener(
        "resize",
        throttle(() => {
          if (this.aboveBreakpoint("md")) {
            this.headerProdsSetPosition();
            this.headerProdsSetWidth();
          }
        }, 25)
      );
    },

    headerProdsSetPosition() {
      const targetLeft = this.noToRemStr(-1 * (this.headerNavWrapLeft() - 2)); // The dropdown's width is defined as 100vw - (2 * 0.125rem). For this reason we subtract by 2 (= 0.125rem @ 1rem = 16px).

      this.headerProds.dropdown.el.style.left = targetLeft;
    },

    headerProdsSetWidth() {
      const targetWidth = this.noToRemStr(
        document.body.getBoundingClientRect().width - 4
      ); // Subtract by 4 to clear the border on the right side.

      this.headerProds.dropdown.el.style.width = targetWidth;
    },

    setMenuLinks(locale) {
      this.menuHeader = require("./locales/header-" + locale + ".json");
    }
  }
};
</script>
