<template>
  <!-- @TODO: Add site links -->
  <div class="site-header__top">
    <div class="site-header__container container">
      <div class="site-header__sites">
        <ul
          class="site-header__items"
          aria-label="VETUS sites"
          v-if="
            menuFlatHeader.flat.top.links != false &&
              menuFlatHeader.flat.top.links != ''
          "
        >
          <template v-for="(link, i) in menuFlatHeader.flat.top.links" :key="i">
            <li class="site-header__item">
              <a
                class="site-header__link"
                :href="link.link.url"
                :target="link.link.target"
                v-html="link.link.title"
              ></a>
            </li>

            <li
              class="site-header__item separator"
              aria-role="presentation"
            ></li>
          </template>

          <li class="site-header__item">
            <router-link
              :to="{ name: 'Home' }"
              class="site-header__link active"
              v-t="'header.websites.webshop'"
              >Webshop</router-link
            >
          </li>
        </ul>
      </div>

      <UnitSelectWide />

      <LangSelectWide />

      <ProfileWide />

      <router-link 
        :to="{ name: 'Quote' }" 
        class="btn btn-sm btn-icon-only"
      >
        <span class="badge badge-rounded" v-if="quoteCount != 0">{{
          quoteCount
        }}</span>
        <span class="sr-only" v-t="'header.menu.quote'">Quote</span>
        <i class="icon icon-description"></i>
      </router-link>

      <router-link
        :to="{ name: 'Cart' }"
        class="btn btn-sm btn-icon-only"
        v-if="isLoggedIn"
      >
        <span class="badge badge-rounded" v-if="cartCount != 0">{{
          cartCount
        }}</span>
        <i class="icon icon-shopping_cart"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import UnitSelectWide from "./Dropdowns/UnitSelectWide.vue";
import LangSelectWide from "./Dropdowns/LangSelectWide.vue";
import ProfileWide from "./Dropdowns/ProfileWide.vue";

export default {
  name: "HeaderTop",
  data() {
    return {
      menuFlatHeader: {}
    };
  },
  watch: {
    "$i18n.locale": function(newVal, oldVal) {
      this.setMenuLinks(newVal);
    }
  },
  components: {
    UnitSelectWide,
    LangSelectWide,
    ProfileWide
  },
  created() {
    this.retrieveCustomerCartId();
    this.retrieveCart();
    this.setMenuLinks(this.$i18n.locale);
  },
  methods: {
    ...mapActions(["retrieveCart", "retrieveCustomerCartId"]),
    setMenuLinks(locale) {
      this.menuFlatHeader = require("./locales/header-" + locale + ".json");
    }
  },
  computed: {
    ...mapGetters(["cart", "cartCount", "quoteCount", "isLoggedIn"])
  }
};
</script>
