<template>
  <div class="dropdown dropdown--profile">
    <button
      data-target="#site-header-top-profile-body"
      class="btn btn-sm btn-dropdown collapsed"
      :class="{ 'd-none': !isLoggedIn }"
      id="site-header-top-profile-toggle"
      role="button"
      data-parent="#site-header"
      data-toggle="collapse"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <i class="icon icon-person_outline"></i>
      <span v-if="authUser && authUser.displayName"
        >{{ $t("account.dashboard.greeting", { name: authUser.displayName }) }}
      </span>
    </button>

    <div
      class="dropdown__body collapse"
      id="site-header-top-profile-body"
      aria-labelledby="site-header-top-profile-toggle"
      aria-expanded="false"
    >
      <div class="collapse__body">
        <ul class="collapse__list">
          <li class="collapse__item">
            <router-link
              :to="{ name: 'Account Dashboard' }"
              class="collapse__link"
              v-t="'account.sidemenu.account'"
              >My account</router-link
            >
          </li>

          <li class="collapse__item">
            <router-link
              :to="{ name: 'Orders' }"
              class="collapse__link"
              v-t="'account.sidemenu.orders'"
              >Orders</router-link
            >
          </li>

          <!-- <li class="collapse__item">
            <router-link
              :to="{ name: 'Address book' }"
              class="collapse__link"
              v-t="'account.sidemenu.address-book'"
              >Address book</router-link
            >
          </li> -->

          <li class="collapse__item">
            <a
              href="#"
              class="collapse__link"
              @click.prevent="userLogout($event)"
              v-t="'header.menu.logout'"
              >Log out</a
            >
          </li>
        </ul>
      </div>
    </div>
    <router-link 
      :to="{ name: 'Login' }"
      class="btn btn-sm" v-if="!isLoggedIn"
    >
      <i class="icon icon-person_outline"></i>
      <span v-t="'header.menu.login'">Login</span>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProfileWide",
  methods: {
    ...mapActions(["signOut", "attempt"]),
    userLogout(e) {
      e.preventDefault();
      this.signOut()
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {
          throw new Error("Logout failed");
        });
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "authUser"])
  }
};
</script>
