<template>
  <section class="section newsletter bg-light py-8 text-center text-sm">
    <div class="section__header mb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <h5 class="mb-2" v-t="'newsletter.title'">
              Subscribe to our newsletter
            </h5>

            <p v-t="'newsletter.content'">
              Receive the latest information about offers, promotions and news.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section__body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="newsletter-input newsletter__ac">
              <ActiveCampaign />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ActiveCampaign from "./ActiveCampaign.vue";

export default {
  components: {
    ActiveCampaign
  },
  // props: ,
  created() {}
};
</script>

<style lang="css" scoped></style>
