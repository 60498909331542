<template>
  <div class="site-header__main">
    <div class="site-header__container container">
      <div class="site-header__logo">
        
    <router-link
        :to="{ name: 'Home' }"
    >
        <img src="@/assets/images/logo/vetus.svg" alt="VETUS" />
      </router-link>

      </div>

      <HeaderSearch v-if="$route.name != 'SearchForm'" />

      <!-- Mobile -->
      <router-link
        :to="{ name: 'Cart' }"
        class="site-header__link site-header__link--icon-only vw-small"
        v-if="isLoggedIn"
      >
        <span class="sr-only" v-t="'header.menu.cart'">Cart</span>
        <i class="icon icon-shopping_cart"></i>
      </router-link>

      <!-- Mobile -->
      <router-link
        :to="{ name: 'Quote' }"
        class="site-header__link site-header__link--icon-only vw-small"
      >
        <span class="sr-only" v-t="'header.menu.quote'">Quote</span>
        <i class="icon icon-description"></i>
      </router-link>

      <!-- Use an <input> to toggle the sibling so that the main <nav> is still accessible if JavaScript is disabled or broken. -->
      <input
        type="checkbox"
        class="site-header__part-wrap-control site-header__part-wrap-control--toggle vw-small"
        id="site-header-bottom-part-wrap-toggle"
        autocomplete="off"
        role="button"
        data-aria-label-open="Open menu"
        data-aria-label-close="Close menu"
        aria-label="Open menu"
        aria-controls="site-header-bottom-part-wrap"
      />

      <span class="tosti-box vw-small">
        <span class="tosti"></span>
      </span>

      <div
        class="site-header__part-wrap"
        id="site-header-bottom-part-wrap"
        aria-expanded="false"
        aria-hidden="true"
        aria-labelledby="site-header-bottom-part-wrap-toggle"
      >
        <!-- "Fake" input to circumvent complex z-index issues. -->
        <input
          type="checkbox"
          class="site-header__part-wrap-control site-header__part-wrap-control--close vw-small"
          id="site-header-bottom-part-wrap-close"
          autocomplete="off"
          role="button"
          checked
          aria-label="Close menu"
          aria-controls="site-header-bottom-part-wrap"
        />

        <span class="tosti-box vw-small">
          <span class="tosti"></span>
        </span>

        <div class="site-header__part">
          <div class="site-header__navigation">
            <HeaderNav />
          </div>

          <hr class="site-header__separator vw-small" />

          <!-- @TODO: Add other site labels for mobile: "Visit coporate websote" / "Visit commercial website". Place them in header.websites -->
          <!-- @TODO: Add other site links -->
          <div class="site-header__sites vw-small">
            <ul class="site-header__items" aria-label="VETUS sites">
              <li class="site-header__item">
                <a
                  class="site-header__link active"
                  href="#"
                  target="_self"
                  v-t="'header.websites.corporate'"
                ></a>
              </li>

              <li class="site-header__item">
                <a
                  class="site-header__link"
                  href="#"
                  target="_self"
                  v-t="'header.websites.commercial'"
                  >Commercial</a
                >
              </li>

              <li class="site-header__item">
                <a
                  class="site-header__link"
                  href="#"
                  target="_self"
                  v-t="'header.websites.webshop'"
                >
                  Webshop
                </a>
              </li>
            </ul>
          </div>

          <router-link
            :to="{ name: 'Cart' }"
            class="site-header__link vw-small"
            v-if="isLoggedIn"
          >
            <i class="icon icon-shopping_cart"></i>
            <span v-t="'header.menu.cart'">Cart</span>
          </router-link>

          <router-link
            :to="{ name: 'Quote' }"
            class="site-header__link vw-small"
          >
            <i class="icon icon-description"></i>
            <span v-t="'header.menu.quote'">Quote</span>
          </router-link>

          <ProfileSmall />

          <UnitSelectSmall />

          <LangSelectSmall />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "@/mixins/helpers";
import header from "@/mixins/header";

import HeaderSearch from "./HeaderSearch.vue";
import HeaderNav from "./HeaderNav.vue";
import UnitSelectSmall from "./Dropdowns/UnitSelectSmall.vue";
import LangSelectSmall from "./Dropdowns/LangSelectSmall.vue";
import ProfileSmall from "./Dropdowns/ProfileSmall.vue";

import { mapGetters } from "vuex";

export default {
  name: "HeaderMain",

  components: {
    HeaderSearch,
    HeaderNav,
    ProfileSmall,
    UnitSelectSmall,
    LangSelectSmall
  },

  mixins: [helpers, header],
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  created() {
    this.elementIsRendered("#site-header-bottom-part-wrap-close").then(() => {
      this.headerToggleInit();
    });
  },
  watch: {
    $route() {
      this.header.partWrap.el.setAttribute("aria-expanded", false);
      this.header.partWrap.el.setAttribute("aria-hidden", true);
      this.header.partWrap.toggle.el.checked = false;
    }
  },
  methods: {
    triggerEvent(el, type) {
      const e = document.createEvent("HTMLEvents");

      e.initEvent(type, true, true);
      el.dispatchEvent(e);
    },
    // Site header - toggles
    headerToggleInit() {
      let header = this.header;
      let that = this;
      this.header.partWrap.toggle.el.addEventListener("input", () => {
        this.headerToggleChecked();
      });

      const mainHeight = this.headerMainHeight();

      this.header.partWrap.close.el.style.top = this.noToRemStr(
        -1 * mainHeight
      );
      this.header.partWrap.close.el.style.height = this.noToRemStr(mainHeight);

      const partWrapCloseTosti = this.header.partWrap.close.el
        .nextElementSibling;

      const partWrapCloseTostiTargetTop = this.noToRemStr(
        -1 * (mainHeight - (mainHeight - 24) / 2)
      ); // .site-header__main height - ((.site-header__main height - .site-header__part-wrap-control height) / 2)

      partWrapCloseTosti.style.top = partWrapCloseTostiTargetTop;

      this.header.partWrap.close.el.addEventListener("click", function(e) {
        e.preventDefault();
        // console.log(header.partWrap);

        header.partWrap.toggle.el.checked = false;

        that.triggerEvent(header.partWrap.toggle.el, "input");
      });
    },
    headerToggleIsChecked() {
      const checked = this.header.partWrap.toggle.el.checked;

      return checked;
    },

    headerToggleSetChecked() {
      const targetLabel = this.header.partWrap.toggle.el.getAttribute(
        "data-aria-label-close"
      );

      this.header.partWrap.toggle.el.setAttribute("aria-label", targetLabel);

      this.header.partWrap.el.setAttribute("aria-expanded", true);
      this.header.partWrap.el.setAttribute("aria-hidden", false);
    },

    headerToggleUnsetChecked() {
      const targetLabel = this.header.partWrap.toggle.el.getAttribute(
        "data-aria-label-open"
      );

      this.header.partWrap.toggle.el.setAttribute("aria-label", targetLabel);

      this.header.partWrap.el.setAttribute("aria-expanded", false);
      this.header.partWrap.el.setAttribute("aria-hidden", true);
    },

    headerToggleChecked() {
      this.headerToggleIsChecked()
        ? this.headerToggleSetChecked()
        : this.headerToggleUnsetChecked();
    }
  }
};
</script>
