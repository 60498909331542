import stylesheet from "../assets/scss/vars/_bootstrap.scss";

export default {
  methods: {
    /*
      Don, als je isEmpty zoekt: die heb ik verwijderd omdat die ingebakken zit in lodash.
      Als je wil weten hoe je die moet importeren, zie Header.vue. Daar importeer ik throttle, maar de import van isEmpty zou volgens hetzelfde principe moeten werken.
      Verwijder deze comment wanneer gelezen.
    */

    // https://forum.vuejs.org/t/js-getboundingclientrect-position-and-vue-component/79235/2
    // Used with elementIsRendered
    waitUntil(selector, scope, resolve, reject) {
      let loopCount = 0;
      let maxLoops = 200;

      // Loops until element exists in DOM or loop times out
      function checkForElement () {
        if (loopCount === maxLoops) {
          loopCount = 0;

          return reject('Timed out waiting for element to render.');
        }

        let el = scope.querySelector(selector);

        setTimeout(() => {
          if (el) {
            loopCount = 0;

            return resolve(el);
          } else {
            loopCount++;

            checkForElement();
          }
        }, 50);
      }

      checkForElement();
    },

    // Returns a resolved Promise once the selector returns an element
    // Useful for when we need to perform an action only when an element is in the DOM
    elementIsRendered(selector, scope = document) {
      return new Promise((resolve, reject) => {
        // start the loop
        return this.waitUntil(selector, scope, resolve, reject);
      });
    },

    // Vanilla JavaScript equivalent to jQuery's .trigger()
    triggerEvent(el, type) {
      const e = document.createEvent("HTMLEvents");

      e.initEvent(type, true, true);
      el.dispatchEvent(e);
    },

    // You could say this is a Node.contains polyfill - https://stackoverflow.com/a/2234986/6396604
    isDescendant(parent, child) {
      let node = child.parentNode;

      while (node != null) {
        if (node == parent) {
          return true;
        }

        node = node.parentNode;
      }

      return false;
    },

    cssValue(el, prop) {
      const value = window.getComputedStyle(el).getPropertyValue(prop);

      return value;
    },

    // https://github.com/scrapjs/css-get-unit
    cssUnit(value) {
      var len = value.length
      if (!value || !len)
        return null

      var i = len
      while (i--)
        if (!isNaN(value[i]))
          return value.slice(i + 1, len) || null

      return null
    },

    // https://github.com/semibran/css-duration
    cssDurationToMs(time) {
      var number = parseFloat(time)
      switch (this.cssUnit(time)) {
        case null:
        case 'ms': return number
        case 's': return number * 1000
        case 'm': return number * 60000
        case 'h': return number * 3600000
        case 'd': return number * 86400000
        case 'w': return number * 604800000
        default: return null
      }
    },

    getScaledSize(number) {
      const baseFontSize     = 16,
            documentFontSize = this.pxStrToNo(this.cssValue(document.documentElement, "font-size"));

      const scalingFactor = documentFontSize / baseFontSize;

      return scalingFactor * number;
    },

    noToRemStr(number) {
      const documentFontSize = this.pxStrToNo(this.cssValue(document.documentElement, "font-size"));

      const remString = (number / documentFontSize).toString() + "rem";

      return remString;
    },

    pxStrToNo(pxString) {
      const number = Number(pxString.replace("px", ""));

      return number;
    },

    aboveBreakpoint(breakpoint) {
      const bp = breakpoint + "Breakpoint";

      if (typeof stylesheet[bp] === "undefined") {
        console.error("The given breakpoint either doesn't exist or hasn't been exported to JavaScript.");
      }

      return window.matchMedia("(min-width: " + stylesheet[bp] + ")").matches;
    },

    colWidth(cols, breakpoint) {
      const containerWidthPx = this.pxStrToNo(stylesheet[breakpoint + "ContainerWidth"]);

      const colWidthMultiFactor = (cols / stylesheet.gridColumns);

      const colWidthPx = (containerWidthPx * colWidthMultiFactor);

      return colWidthPx;
    }
  }
};
