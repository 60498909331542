<template>
  <!-- @TODO: Add ups via labels-->
  <div class="site-header__usps">
    <span class="site-header__usp" v-t="'header.usp.usp1'"></span>
    <span class="site-header__usp" v-t="'header.usp.usp2'"></span>
    <span class="site-header__usp" v-t="'header.usp.usp3'"></span>
    <span class="site-header__usp" v-t="'header.usp.usp4'"></span>
  </div>
</template>

<script>
export default {
  name: "HeaderUsps"
};
</script>
