<template>
  <Header>
    <router-view name="headerContent" />
  </Header>

  <main>
    <router-view :key="$route.name != 'SearchForm' ? $route.fullPath : ''" />
    <Newsletter></Newsletter>
  </main>
  <Footer></Footer>
</template>

<script>
import Header from "./components/Layout/Header/Header.vue";
import Footer from "./components/Layout/Footer/Footer.vue";
import Newsletter from "./components/Newsletter/Section.vue";

export default {
  components: {
    Header,
    Footer,
    Newsletter
  },
  props: {
    header: {
      type: Object
    }
  },
  mounted() {
    let dropdownBtns = document.querySelectorAll(".btn-dropdown");
    let dropdownBodies = document.querySelectorAll(".dropdown__body");
    document.body.addEventListener("click", function(e) {
      if (!e.target.classList.contains("btn-dropdown")) {
        dropdownBtns.forEach(function(btn) {
          btn.classList.add("collapsed");
          btn.setAttribute("aria-expanded", "false");
        });
        dropdownBodies.forEach(function(dropdownBody) {
          dropdownBody.classList.remove("collapsed");
          dropdownBody.classList.remove("show");
          dropdownBody.classList.add("collapse");
          dropdownBody.setAttribute("aria-expanded", "false");
        });
      }
    });
  }
};
</script>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
