<template>
  <a
    class="site-header__link site-header__link--icon-only vw-small"
    id="header-search-toggle-vw-small"
    href="#"
    target="_self"
    role="button"
    @click="openSearch"
  >
    <span
      class="sr-only"
      data-label-open="Open search bar"
      data-label-close="Close search bar"
      v-t="'header.menu.search-bar'"
    >
      Open search bar
    </span>
    <i class="icon icon-search"></i>
  </a>

  <div
    class="site-header__search"
    :class="{ 'has-results': searchProducts && searchProducts.items }"
    id="header-search"
  >
    <div class="header-search-placeholder"></div>

    <div class="header-search">
      <input
        class="header-search__field text-sm"
        type="text"
        disabled
        aria-expanded="false"
        aria-hidden="true"
        aria-labelledby="header-search-icon"
        v-model="searchValue"
        @input="searchChanged"
        @keyup.enter="headerSearchSubmit()"
      />

      <button class="header-search__button" type="button" tabindex="-1">
        <i
          class="header-search__icon icon icon-search"
          id="header-search-icon"
          aria-label="Search"
          data-label-open="Open search bar"
          data-label-close="Close search bar"
          data-label-submit="Search"
        >
        </i>
        <span
          class="header-search__label"
          aria-hidden="true"
          v-t="'header.menu.search'"
          >Search</span
        >
      </button>

      <div class="header-search__results-wrap" v-if="searchProducts">
        <ul class="header-search__results" aria-label="Search results">
          <li
            class="header-search__result"
            v-for="(product, i) in searchProducts.items"
            :key="i"
          >
            <router-link
              :to="{
                name: 'ProductSingle',
                params: {
                  locale: this.$i18n.locale,
                  productSlug: product.url_key,
                },
              }"
              class="header-search__link"
              tabindex="-1"
              :title="product.name"
              @click="headerSearchHideBar"
            >
              {{ product.name }}
            </router-link>
          </li>

          <li class="header-search__separator" aria-role="presentation"></li>

          <li class="header-search__background" aria-role="presentation"></li>
        </ul>
      </div>

      <div class="header-search__background"></div>
    </div>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import helpers from "@/mixins/helpers";
import header from "@/mixins/header";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HeaderSearch",

  mixins: [helpers, header],

  data() {
    return {
      searchValue: "",
      headerSearch: {
        toggleVwSmall: {
          el: null
        },

        wrapper: {
          el: null,

          elBackground: null
        },

        button: {
          el: null
        },

        icon: {
          el: null
        },

        label: {
          el: null
        },

        field: {
          el: null
        },

        results: {
          el: null,

          elBackground: null
        }
      },
      awaitingSearch: false
    };
  },
  mounted() {
    this.elementIsRendered(
      ".header-search__results > .header-search__background"
    ) // this is the "deepest, latest" dropdown
      .then(() => {
        (this.headerSearch.toggleVwSmall.el = document.querySelector(
          "#header-search-toggle-vw-small"
        )),
          (this.headerSearch.wrapper.el = document.querySelector(
            ".header-search"
          )),
          (this.headerSearch.wrapper.elBackground = document.querySelector(
            ".header-search > .header-search__background"
          )),
          (this.headerSearch.button.el = document.querySelector(
            ".header-search__button"
          )),
          (this.headerSearch.icon.el = document.querySelector(
            ".header-search__icon"
          )),
          (this.headerSearch.label.el = document.querySelector(
            ".header-search__label"
          )),
          (this.headerSearch.field.el = document.querySelector(
            ".header-search__field"
          )),
          (this.headerSearch.results.el = document.querySelector(
            ".header-search__results"
          )),
          (this.headerSearch.results.elBackground = document.querySelector(
            ".header-search__results > .header-search__background"
          )),
          this.headerSearchInit();
      })
      .catch(error => {
        //console.log(error);
      });

    document.body.addEventListener("click", function(e) {
      if (e.target.classList.contains("header-search__link")) {
        document.querySelector(".header-search__results").style.visibility =
          "hidden";
      }
    });
  },

  computed: {
    ...mapGetters(["searchProducts"])
  },
  created() {
    document.addEventListener("click", this.onClick);
  },
  methods: {
    ...mapActions(["fetchSearchProducts"]),
    onClick(event) {
      let headerSearch = document.getElementsByClassName("header-search");
      if (
        !event.target.classList.contains("header-search__field") &&
        headerSearch[0].classList.contains("is-active")
      ) {
        this.headerSearchHideBar();
      }
    },
    openSearch(e) {
      let header = this.header;
      let that = this;
      e.preventDefault();
      header.partWrap.toggle.el.checked = false;
      that.triggerEvent(header.partWrap.toggle.el, "input");
    },
    searchChanged() {
      if (this.searchValue.length > 1 && !this.awaitingSearch) {
        setTimeout(() => {
          this.fetchSearchProducts({
            searchValue: this.searchValue,
            sortOption: "relevance:DESC",
            pageNumber: 1
          });
          this.awaitingSearch = false;
        }, 1000);
        this.awaitingSearch = true;
      }
    },

    headerSearchInit() {
      if (this.headerSearch.wrapper.el) {
        this.headerSearchAccessibilityFixer("load");

        this.headerSearch.toggleVwSmall.el.addEventListener("click", () => {
          this.headerSearchToggleBar();
        });

        this.headerSearch.field.el.addEventListener("input", () => {
          this.headerSearchAccessibilityFixer("input");
        });

        this.headerSearch.button.el.addEventListener("click", () => {
          this.headerSearch.field.el.value !== ""
            ? this.headerSearchSubmit()
            : this.headerSearchToggleBar();
        });

        window.addEventListener(
          "resize",
          throttle(() => {
            this.headerSearchAccessibilityFixer("resize");

            if (
              this.headerSearchIsShown() &&
              !this.headerSearchIsTransitioning()
            ) {
              this.headerSearchSetSize("all");
            }
          }, 25)
        );
      }
    },

    headerSearchElTargetWidth(el) {
      let size;

      if (el === "wrapper") {
        if (!this.aboveBreakpoint("md")) {
          size =
            this.headerSearchButtonClientRect("right") -
            this.headerSearchWrapperExtensionToRight();
        } else {
          if (this.aboveBreakpoint("xxl")) {
            size = this.colWidth(3, "xxl");
          } else if (this.aboveBreakpoint("xl")) {
            size = this.colWidth(4, "xl");
          } else if (this.aboveBreakpoint("lg")) {
            size = this.colWidth(5, "lg");
          } else {
            size = this.colWidth(6, "md");
          }
        }
      } else if (el === "label") {
        size = this.headerSearch.label.el.getBoundingClientRect().width;
      } else if (el === "field") {
        if (!this.aboveBreakpoint("md")) {
          size =
            this.headerSearchButtonClientRect("left") - this.getScaledSize(8);
        } else {
          if (this.aboveBreakpoint("xxl")) {
            size = this.colWidth(3, "xxl");
          } else if (this.aboveBreakpoint("xl")) {
            size = this.colWidth(4, "xl");
          } else if (this.aboveBreakpoint("lg")) {
            size = this.colWidth(5, "lg");
          } else {
            size = this.colWidth(6, "md");
          }

          const iconWidth = this.headerSearchIconClientRect("width"),
            iconSpacing = this.getScaledSize(8 + 8); // 8 pixels on both sides, according to the design.

          size = size - (iconWidth + iconSpacing);
        }
      } else if (el === "results") {
        if (!this.aboveBreakpoint("md")) {
          size =
            this.headerSearchButtonClientRect("right") + this.getScaledSize(8);
        } else {
          if (this.aboveBreakpoint("xxl")) {
            size = this.colWidth(3, "xxl");
          } else if (this.aboveBreakpoint("xl")) {
            size = this.colWidth(4, "xl");
          } else if (this.aboveBreakpoint("lg")) {
            size = this.colWidth(5, "lg");
          } else {
            size = this.colWidth(6, "md");
          }
        }
      } else {
        return;
      }

      return size;
    },

    headerSearchWrapperExtensionToRight() {
      const extension = this.aboveBreakpoint("md") ? 16 : 8;

      return this.getScaledSize(extension);
    },

    headerSearchButtonClientRect(value) {
      return this.headerSearch.button.el.getBoundingClientRect()[value];
    },

    headerSearchIconClientRect(value) {
      return this.headerSearch.icon.el.getBoundingClientRect()[value];
    },

    headerSearchGetTransitionDuration() {
      const value = this.cssValue(
          this.headerSearch.field.el,
          "transition-duration"
        ),
        transDur = this.cssDurationToMs(value);

      return transDur;
    },

    headerSearchStartTransition() {
      this.headerSearch.wrapper.el.classList.add("is-transitioning");

      setTimeout(() => {
        // Fire a headerSearchTransitioned event (or something like that).
        this.headerSearch.wrapper.el.classList.remove("is-transitioning");
      }, this.headerSearchGetTransitionDuration());
    },

    headerSearchSetSize(els) {
      if (els === "all") {
        els = [
          this.headerSearch.wrapper.el,
          this.headerSearch.wrapper.elBackground,
          this.headerSearch.label.el,
          this.headerSearch.field.el,
          this.headerSearch.results.el,
          this.headerSearch.results.elBackground
        ];
      }

      els.forEach(el => {
        const elClassName = el.classList[0];

        if (!/(header-search){1}(__[A-Za-z0-9]+)?$/.test(elClassName)) {
          console.error(
            "The first class in the element's classList must be the 'header-search' class."
          );

          return;
        }

        // Give the element a name.
        let elName = elClassName;

        // Check if the element is a child of .header-search.
        if (/(header-search__[A-Za-z0-9]+)$/.test(elName)) {
          elName = elName.replace("header-search__", "");

          if (elName === "background") {
            const parentElName = el.parentNode.classList[0].replace(
              "header-search__",
              ""
            );

            elName = parentElName;
          }
        }

        if (elName.indexOf("header-search") > -1) {
          elName = "wrapper";
        }

        // Do stuff based on the element's name.
        if (elName === "label") {
          // const targetLeft = noToRemStr(this.headerSearch[elName].targetWidth());
          const targetLeft = this.noToRemStr(
            this.headerSearchElTargetWidth(elName)
          );

          this.headerSearch[elName].el.style.left = targetLeft;
          this.headerSearch.icon.el.style.left = targetLeft;

          return;
        }

        // const targetWidth = noToRemStr(this.headerSearch[elName].targetWidth());
        const targetWidth = this.noToRemStr(
          this.headerSearchElTargetWidth(elName)
        );

        el.style.width = targetWidth;
      });
    },

    headerSearchUnsetSize(els) {
      if (els === "all") {
        els = [
          this.headerSearch.wrapper.el,
          this.headerSearch.wrapper.elBackground,
          this.headerSearch.label.el,
          this.headerSearch.field.el,
          this.headerSearch.results.el,
          this.headerSearch.results.elBackground
        ];
      }

      els.forEach(el => {
        const elClassName = el.classList[0];

        if (!/(header-search){1}(__[A-Za-z0-9]+)?$/.test(elClassName)) {
          console.error(
            "The first class in the element's classList must be the 'header-search' class."
          );

          return;
        }

        // Give the element a name.
        let elName = elClassName;

        if (/(header-search__[A-Za-z0-9]+)$/.test(elClassName)) {
          // If the class name is a BEM element.
          elName = elClassName.replace("header-search__", "");

          if (elName === "background") {
            const parentElName = el.parentNode.classList[0].replace(
              "header-search__",
              ""
            );

            elName = parentElName;
          }
        }

        // if (elName.indexOf("header-search") > -1) {
        // 	elName = "wrapper";
        // }

        // Do stuff based on the element's name.
        if (elName === "label") {
          this.headerSearch[elName].el.style.left = "";
          this.headerSearch.icon.el.style.left = "";

          return;
        }

        el.style.width = "";
      });
    },

    headerSearchClearResults() {
      //console.log("headerSearch.clearResults() was called!");
      this.headerSearch.wrapper.el.classList.remove("has-results");
      this.searchValue = "";
      this.searchChanged();
    },

    headerSearchAccessibilityFixer(action) {
      const buttonTabIndex = this.headerSearch.button.el.getAttribute(
        "tabindex"
      );

      const toggleLabelEl = this.headerSearch.toggleVwSmall.el
          .firstElementChild,
        buttonLabelEl = this.headerSearch.button.el.firstElementChild;

      if (action === "load" || action === "resize") {
        if (this.headerSearchIsShown()) {
          return;
        }

        let buttonTargetLabel;

        if (!this.aboveBreakpoint("md")) {
          if (buttonTabIndex !== "-1") {
            this.headerSearch.button.el.setAttribute("tabindex", "-1");
          }

          buttonTargetLabel = buttonLabelEl.getAttribute("data-label-submit");
        } else {
          if (buttonTabIndex !== null || buttonTabIndex === "") {
            this.headerSearch.button.el.removeAttribute("tabindex");
          }

          buttonTargetLabel = buttonLabelEl.getAttribute("data-label-open");
        }

        buttonLabelEl.setAttribute("aria-label", buttonTargetLabel);
      }

      if (action === "show" || action === "hide") {
        const toggleLabelType = action === "show" ? "close" : "open",
          toggleTargetLabel = toggleLabelEl.getAttribute(
            "data-label-" + toggleLabelType
          );

        toggleLabelEl.textContent = toggleTargetLabel;

        const buttonLabelType =
            action === "show"
              ? "close"
              : this.aboveBreakpoint("md")
              ? "open"
              : "submit",
          buttonTargetLabel = buttonLabelEl.getAttribute(
            "data-label-" + buttonLabelType
          );

        buttonLabelEl.setAttribute("aria-label", buttonTargetLabel);

        if (action === "show") {
          this.headerSearch.button.el.removeAttribute("tabindex");
        } else {
          if (!this.aboveBreakpoint("md") && buttonTabIndex !== "-1") {
            this.headerSearch.button.el.setAttribute("tabindex", "-1");
          }
        }
      }

      if (action === "input") {
        const buttonLabelType =
            this.headerSearch.field.el.value === "" ? "close" : "submit",
          buttonTargetLabel = buttonLabelEl.getAttribute(
            "data-label-" + buttonLabelType
          );

        buttonLabelEl.setAttribute("aria-label", buttonTargetLabel);
      }
    },

    headerSearchHideBar() {
      if (this.headerSearchIsTransitioning()) {
        return false;
      }

      //console.log("Hiding headerSearch...");

      this.headerSearchStartTransition();

      if (!this.aboveBreakpoint("md")) {
        const buttonRight = this.cssValue(this.headerSearch.button.el, "right");

        if (buttonRight === "0" || buttonRight === "0px") {
          this.headerSearch.button.el.style.right = "";
        }
      }

      this.headerSearchAccessibilityFixer("hide");

      if (this.headerSearch.field.el === document.activeElement) {
        this.headerSearch.button.el.focus();
      }

      // this.headerSearch.field.el.setAttribute("disabled", "true");
      this.headerSearch.field.el.setAttribute("aria-expanded", "false");
      this.headerSearch.field.el.setAttribute("aria-hidden", "true");

      this.headerSearchUnsetSize("all");

      if (this.headerSearch.field.el.value !== "") {
        this.headerSearch.field.el.value = "";
      }

      // This would preferably be run after a "headerSearchTransitioned" event fires.
      setTimeout(() => {
        this.headerSearchClearResults();

        this.headerSearch.results.el.style.visibility = "";
      }, this.headerSearchGetTransitionDuration());

      this.headerSearch.wrapper.el.classList.remove("is-active");
      this.headerSearch.wrapper.el.classList.remove("has-results");

      document.body.classList.remove("open-search");
    },

    headerSearchShowBar() {
      if (this.headerSearchIsTransitioning()) {
        return false;
      }

      //console.log("Showing headerSearch...");

      this.headerSearchStartTransition();

      if (!this.aboveBreakpoint("md")) {
        const buttonRight = this.cssValue(this.headerSearch.button.el, "right");

        if (buttonRight !== "0" || buttonRight !== "0px") {
          this.headerSearch.button.el.style.right = "0";
        }
      }

      this.headerSearchAccessibilityFixer("show");

      this.headerSearchSetSize("all");

      this.headerSearch.field.el.removeAttribute("disabled");
      this.headerSearch.field.el.setAttribute("aria-expanded", "true");
      this.headerSearch.field.el.setAttribute("aria-hidden", "false");
      this.headerSearch.field.el.focus();

      this.headerSearch.results.el.style.visibility = "visible";

      this.headerSearch.wrapper.el.classList.add("is-active");
      this.headerSearch.wrapper.el.classList.add("has-results");

      document.body.classList.add("open-search");

      this.searchProducts.items = [];
    },

    headerSearchToggleBar() {
      this.headerSearchIsShown()
        ? this.headerSearchHideBar()
        : this.headerSearchShowBar();
    },

    headerSearchSubmit() {
      if (this.searchValue.length > 1) {
        // this.searchProducts.items = {};
        this.$router.push({
          name: "SearchForm",
          query: { q: this.searchValue }
        });
      }
    },

    headerSearchIsTransitioning() {
      const transitioning = this.headerSearch.wrapper.el.classList.contains(
        "is-transitioning"
      );

      return transitioning;
    },

    headerSearchIsShown() {
      const shown = this.headerSearch.wrapper.el.classList.contains(
        "is-active"
      );

      return shown;
    }
  }
};
</script>
