<template>
  <section>

    <!-- Engelstalig formulier -->
    <div v-if="$i18n.locale == 'en'" style="text-align: center;">
      <form method="POST" action="https://vetus.activehosted.com/proc.php" id="_form_103_" class="_form _form_103 _inline-form _inline-style _dark" validate>
        <input type="hidden" name="u" value="103" />
        <input type="hidden" name="f" value="103" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <input type="hidden" name="or" value="5048febacdd50e444afe6e9e6b0a183e" />
        <div class="_form-content">

          <div class="_form_element _x92850053 _inline-style " >
            <div class="_field-wrapper">
              <input type="text" id="firstname" name="firstname" placeholder="Fill in your first name" required/>
            </div>
          </div>
          <div class="_form_element _x77958056 _inline-style " >
            <div class="_field-wrapper">
              <input type="text" id="lastname" name="lastname" placeholder="Fill in your last name" required/>
            </div>
          </div>
          <div class="_form_element _x77655280 _inline-style " >
            <div class="_field-wrapper">
              <input type="text" id="email" name="email" placeholder="Fill in your email address" required/>
            </div>
          </div>
          <div class="_button-wrapper _inline-style">
            <button id="_form_103_submit" class="_submit" type="submit">
              Send
            </button>
          </div>
          <div class="_clear-element">
          </div>
        </div>
        <div class="_form-thank-you" style="display:none;">
        </div>
      </form>
    </div>

    <!-- Nederlandstalig formulier -->
    <div v-if="$i18n.locale == 'nl'" style="text-align: center;">

    <form method="POST" action="https://vetus.activehosted.com/proc.php" id="_form_107_" class="_form _form_107 _inline-form _inline-style _dark" validate>
      <input type="hidden" name="u" value="107" />
      <input type="hidden" name="f" value="107" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />
      <input type="hidden" name="or" value="b69f46a3f465fc868425cc1e491aa2b9" />
      <div class="_form-content">
        <div class="_form_element _x92850053 _inline-style " >
          <div class="_field-wrapper">
            <input type="text" id="firstname" name="firstname" placeholder="Vul je voornaam in" required/>
          </div>
        </div>
        <div class="_form_element _x77958056 _inline-style " >
          <div class="_field-wrapper">
            <input type="text" id="lastname" name="lastname" placeholder="Vul je achternaam in" required/>
          </div>
        </div>
        <div class="_form_element _x77655280 _inline-style " >
          <div class="_field-wrapper">
            <input type="text" id="email" name="email" placeholder="Vul je mailadres in" required/>
          </div>
        </div>
        <div class="_button-wrapper _inline-style">
          <button id="_form_107_submit" class="_submit" type="submit">
            Versturen
          </button>
        </div>
        <div class="_clear-element">
        </div>
      </div>
      <div class="_form-thank-you" style="display:none;">
      </div>
    </form>
  </div>

  </section>
</template>

<script>
export default {
  components: {},
  created() {},
  mounted() {

    //If locale = en load english form, if nl load dutch form
    let id = 0;
    if (this.$i18n.locale == 'en') {
        id = 103;
    } else if (this.$i18n.locale == 'nl') {
        id = 107;
    }

      // let externalScript = document.createElement('script');
      // externalScript.setAttribute('src', 'https://vetus.activehosted.com/f/embed.php?id=' + id);
      // document.head.appendChild(externalScript);
  }

};
</script>

<style lang="scss" scoped></style>
